<template>
    <section class="user-categories">
        <tool-bar>
            <template slot="buttons">
                <div class="level-item">
                    <button class="button">
                        <span class="icon is-small">
                            <font-awesome-icon icon="plus"></font-awesome-icon>
                        </span>
                        <span>Add a category</span>
                    </button>
                </div>
            </template>
            User Categories
        </tool-bar>
        <div class="table-wrapper">
            <b-table detailed
                     detail-key="_id"
                     v-bind:data="categories">
                <template>
                    <b-table-column v-slot="{ row }"
                                    field="id"
                                    label="Added on"
                                    centered>
                        {{ row.createdAt | dateFnsFormatter("d/MMM/yyyy hh:mm a") }}
                    </b-table-column>

                    <b-table-column v-slot="{ row }"
                                    field="name"
                                    label="Name"
                                    centered
                                    sortable>
                        <strong>{{ row.name }}</strong>
                    </b-table-column>

                    <b-table-column v-slot="{ row }"
                                    field="count"
                                    label="Members"
                                    centered
                                    sortable>
                        {{ row.count }}
                    </b-table-column>

                    <b-table-column v-slot="{ row }"
                                    label=""
                                    centered>
                        <router-link class="button is-small is-text"
                                     v-bind:to="{ name: 'user-details', params: { id: row._id } }">
                            <font-awesome-icon icon="edit"></font-awesome-icon>
                        </router-link>
                        <button class="button is-small is-text is-danger"
                                v-bind:class="{ 'is-loading': isDeleting }"
                                v-on:click="reauthenticateDelete(row)">
                            <font-awesome-icon icon="trash-alt"></font-awesome-icon>
                        </button>
                    </b-table-column>
                </template>


            </b-table>
        </div>
    </section>
</template>

<script>
export default {
    name: "UserCategories",
    components: {
        ToolBar: () => import("../layouts/ToolBar.vue"),
    },
    data () {
        return {

        };
    },
    computed: {
        categories () {
            return [
                {
                    _id: "d286ebba-862a-4233-acce-aad9bc9c9ae1",
                    name: "Ordinary",
                    count: 298,
                    createdAt: new Date(2022, 9, 5, 11, 25),
                },
                {
                    _id: "8cb9e8ec-82cc-4689-9daf-2740f50cbf81",
                    name: "VIP",
                    count: 0,
                    createdAt: new Date(2022, 9, 5, 11, 32),
                }
            ];
        },
    },
    methods: {

    },
}
</script>

<style lang="scss" scoped>
.table-wrapper {
    padding: 1rem;
}
</style>
